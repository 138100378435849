import styled, { css } from 'styles/styled';

const textStyles = css`
  font-size: ${({ theme }) => theme.fontSize.baseMobile};
  color: ${({ theme }) => theme.palette.lightDark};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  line-height: 1.5;
  letter-spacing: 0.1px;

  @media ${({ theme }) => theme.devices.large} {
    font-size: ${({ theme }) => theme.fontSize.base};
    line-height: 1.1;
  }
`;

export const MainWrapper = styled.div`
  max-width: ${({ theme }) => theme.dimensions.contentMaxWidthBig}px;
  position: relative;
  z-index: 1;
  ul {
    list-style-type: initial;
    padding-left: 25px;
    margin-bottom: 20px;

    p {
      margin-bottom: 20px;
    }
  }

  li {
    ${textStyles};
    line-height: 22px !important;
  }

  section {
    > div {
      display: none;
      @media ${({ theme }) => theme.devices.medium} {
        display: block;
      }
    }

    article {
      padding: 0;
      @media ${({ theme }) => theme.devices.medium} {
        padding: 0 16px;
      }
      @media ${({ theme }) => theme.devices.xlarge} {
        padding: 0 0 0 80px;
      }

      h2 {
        margin-left: 0;
        @media ${({ theme }) => theme.devices.medium} {
          margin-left: 80px;
        }

        @media ${({ theme }) => theme.devices.xlarge} {
          margin-left: 0;
        }
      }
    }
  }
`;

export const Wrapper = styled.div`
  @media ${({ theme }) => theme.devices.large} {
    margin-left: 80px;
  }

  a {
    font-weight: bold;
  }
`;

export const Text = styled.p<{ marginBottom?: string }>`
  ${textStyles};
  margin-top: 20px;
  margin-bottom: ${({ marginBottom }) => marginBottom ?? '30px'};
  line-height: 22px !important;
`;

export const Title = styled.h3`
  width: 100%;
  font-size: ${({ theme }) => theme.fontSize.label};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.palette.light};
  margin-bottom: ${({ theme }) => theme.dimensions.spacingBase10}px;
  text-align: left;
`;
