import styled from 'styles/styled';

// eslint-disable-next-line import/prefer-default-export
export const Button = styled.button`
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.label};
  color: ${({ theme }) => theme.palette.light};
  background-color: transparent;
  text-transform: uppercase;
  cursor: pointer;
  margin: ${({ theme }) => theme.dimensions.spacingBase10}px auto;
  transition: ${({ theme }) => theme.ui.transition('opacity')};
  width: fit-content;
  outline: 0;

  &:hover {
    opacity: ${({ theme }) => theme.ui.opacity()};
  }

  &:before {
    content: '';
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    display: inline-block;
    height: 8px;
    width: 8px;
    margin-right: ${({ theme }) => theme.dimensions.spacingBase10 / 2}px;
    margin-top: 3px;
    transform: rotate(135deg);
  }

  @media ${({ theme }) => theme.devices.large} {
    margin: ${({ theme }) => theme.dimensions.spacingBase10}px 0;
  }
`;
