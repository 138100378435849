import React from 'react';
import { navigate } from '@reach/router';

import { Button } from './styled';

interface Props {
  className?: string;
  text?: string;
}

const BackButton = ({
  className,
  text = 'back',
  ...props
}: Props & Omit<JSX.IntrinsicElements['button'], 'ref'>) => (
  <Button className={className} onClick={() => navigate(-1)} {...props}>
    {text}
  </Button>
);
export default BackButton;
