import styled from 'styles/styled';

export const Wrapper = styled.section`
  position: relative;
  max-width: 400px;
  margin: 0 auto;

  h3 {
    margin-bottom: ${({ theme }) => theme.dimensions.spacingBase10 * 1.5}px;
  }

  @media ${({ theme }) => theme.devices.medium} {
    max-width: 100%;
    margin: 0 80px;

    h3 {
      margin-bottom: ${({ theme }) => theme.dimensions.spacingBase10 * 2}px;
    }
  }

  @media ${({ theme }) => theme.devices.large} {
    margin: 0;
  }
`;

export const TextWrapper = styled.article`
  padding: 0 16px;

  @media ${({ theme }) => theme.devices.xlarge} {
    padding: 0 0 0 80px;
  }
`;
