import styled from 'styles/styled';

// eslint-disable-next-line import/prefer-default-export
export const LineShape = styled.div`
  display: none;

  @media ${({ theme }) => theme.devices.medium} {
    position: absolute;
    top: -66px;
    left: 0;
    width: 4px;
    height: 86px;
    display: block;
    transform: rotate(45deg);
    transform-origin: bottom left;
    background-image: ${({ theme }) => theme.gradient.primary};
    width: 8px;
    height: 140px;
    top: -104px;
  }
`;
