/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import TopSection from 'components/TopSection';
import { StyledLink } from 'components/JoinUs/styled';
import BackButton from 'components/ui/BackButton';

import { useWindowSize } from 'hooks';

import { MainWrapper, Wrapper, Text, Title } from './styled';

const CLOUDBEST_URL = 'https://cloudbest.com';
const SECURITY_MAIL = 'mailto: security@cloudbest.com';
const GOOGLE_URL = 'https://google.com/';
const LINKED_IN_URL = 'https://linkedin.com';
const FACEBOOK_URL = 'https://facebook.com';
const YOU_TUBE_URL = 'https://youtube.com';
const INSTAGRAM_URL = 'https://instagram.com';

const PrivacyPolicy = ({ data }: any) => {
  const { pageTitle } = data;

  const windowSize = useWindowSize();
  const width = windowSize?.width ?? 0;

  return (
    <MainWrapper>
      {/* These inline styles are a quick fix for missing styles in production build */}
      <BackButton
        style={{
          marginTop: width >= 990 ? '30px' : '55px',
          marginBottom: width >= 990 ? '90px' : '65px',
        }}
      />
      <TopSection title={pageTitle} />
      <Wrapper>
        <Title>GENERAL INFORMATION </Title>
        <Text>
          The data Controller is CLOUD BEST SP. Z O.O., with its registered
          office in Warsaw, Poland, Wioślarska 8, incorporated under Polish law
          and registered in the Polish Court Registered kept by the 9th
          Commercial Department of District Court for the city of Koszalin under
          number KRS 0000778403, tax identification number NIP 6692552456,
          statistical number REGON 382893418 (hereinafter referred to as ‘the
          Company’ or ‘the Controller’) may collect and process data (including
          personal data) concerning the users (hereinafter the ‘User’) of this
          website and/or any services (i.e. technical assistance/support, means
          of communication, customer services, complaints) (hereinafter the
          ‘Services’).
          <br />
          <br />
          The Controller takes care to ensure a high standard of protection of
          the users, interested parties and visitors to&nbsp;
          <StyledLink href={CLOUDBEST_URL}>www.cloudbest.com.</StyledLink> This
          Privacy Policy (hereinafter the ‘Policy’) sets forth the rules for the
          collection, processing and use of the personal data of the website’s
          users, interested parties and visitors.
          <br />
          <br />
          The purpose of this Policy is primarily to inform the users, visitors
          and interested parties about their rights in relation to the
          processing of their data by the Controller.
          <br />
          <br />
          In the event of any questions or concerns regarding data protection,
          please contact the Controller directly:&nbsp;
          <StyledLink href={SECURITY_MAIL}>security@cloudbest.com.</StyledLink>
        </Text>
        <Title>PROTECTION OF PERSONAL DATA</Title>
        <Text>
          Any personal data are processed by the Controller in accordance with
          Regulation (EU) 2016/679 of the European Parliament and of the Council
          of 27 April 2016 on the protection of natural persons regarding the
          processing of personal data and on the free movement of such data,
          repealing Directive 95/46/EC (further: ‘GDPR’).
          <br />
          <br />
          The Controller does not intentionally collect personal data of
          children aged below 16, without the consent of the child’s parent or
          legal guardian. In the case of doubt, parents and legal guardians are
          asked to contact the Company at&nbsp;
          <StyledLink href={SECURITY_MAIL}>security@cloudbest.com.</StyledLink>
          &nbsp;
        </Text>
        <Title>PURPOSE AND LEGAL BASIS OF PERSONAL DATA PROCESSING</Title>
        <Text marginBottom="15px">
          Your personal data may be processed for the purpose of:
        </Text>
        <ul>
          <li>
            performing a contract or taking an action at your request prior to
            the conclusion of a contract, and for the purpose of pursuing claims
            under the contract - Article 6(1)(b) and/or (f) GDPR;
          </li>

          <li>
            answering the questions, you asked via the contact form and/or
            address e-mail, which is in the exercise of the Company legitimate
            interest - Article 6(1)(f) GDPR;&nbsp;
          </li>

          <li>
            fulfilling the Company’s legal obligations, including tax and
            accounting legislation -Article 6(1)(c) GDPR;
          </li>

          <li>
            direct marketing activities through the information channel of your
            choice as well as adapting our websites, offers and advertising to
            your interests, which are based on your voluntary and explicit
            consent - Article 6(1)(a) GDPR;&nbsp;
          </li>

          <li>
            providing you marketing information about our products and services
            as well as relevant third-party products and services by other
            means, such as advertising on websites which is based on Company
            legitimate interest - Article 6(1)(f) GDPR;&nbsp;
          </li>
        </ul>
        <Text>
          The provision of personal data by users is voluntary but is required
          in order to be able to use the Controller’s services provided
          via&nbsp;
          <StyledLink href={CLOUDBEST_URL}>www.cloudbest.com.</StyledLink>
        </Text>
        <Title>CATEGORIES OF DATA PROCESSED</Title>
        <Text>
          Depending on the type of the Services and the scope of consents
          granted by the User, the Company processes one or more information of
          the Data stored: name/surname, e-mail address, phone number, IP
          address; and other technical information (e.g. regarding type of
          devices and other tools, web browser), information necessary to
          provide technical assistance or support (e.g. parameters, logs, other
          descriptions), pages opened, duration of the visit, number of the
          various page views, number of visits, referral source, data of the ISP
          and the subscriber’s data - however, these shall only be used for
          statistical purposes or to ensure the correct operation of the
          website.
        </Text>
        <Title>YOUR RIGHTS</Title>
        <Text marginBottom="15px">
          Under the applicable law, the following rights are vested in User, in
          particular:
        </Text>

        <ul>
          <li>the right to access to the Personal Data; </li>
          <li>
            the right to request to rectify, correct or delete the Personal
            Data;
          </li>
          <li>
            the right to object (forbid) to the processing of Personal Data;
          </li>
          <li>
            the right to request a restriction of the Personal Data processing;
          </li>
          <li>
            the right to request a transfer of the Personal Data to another
            entity;
          </li>
        </ul>
        <Text marginBottom="15px">
          The User has the right to request the erasure of personal data (the
          right to be forgotten) without undue delay if:&nbsp;
        </Text>
        <ul>
          <li>
            the Personal Data are no longer necessary in relation to the
            purposes for which they were collected or otherwise processed;
          </li>
          <li>
            the consent given earlier has been effectively revoked, unless The
            Company have the right to process the data on another legal ground;
          </li>
          <li>
            the User objects to the processing of Personal Data for marketing
            purposes;
          </li>
          <li>
            the deletion of the Personal Data would be necessary to fulfil The
            Company’s legal obligation;
          </li>
          <li>
            the processing of the data was not in accordance with the applicable
            law;
          </li>
          <li>
            the User objects to the processing (also by profiling) of Personal
            Data, based on the legally legitimate interest pursued by the
            Company or by the third party, unless there are valid, legally
            justified grounds for processing of the User’s Personal Data that
            override the interests, rights and freedoms of the User or there are
            grounds for establishing, exercising or defending legal claims.
          </li>
        </ul>
        <Text>
          The User has the right to withdraw the consent to the processing of
          Personal Data at any time, but the withdrawal does not affect the
          lawfulness of processing based on consent before its withdrawal.
          <br />
          <br /> The User has the right to submit complaint to a data protection
          authority – President of the Personal Data Protection Authority
          (PUODO) in Poland. You can exercise this right when you believe that
          we are processing your data without justification or in a manner
          inconsistent with applicable law.
          <br />
          <br /> In order to exercise the aforesaid right or in the case of any
          questions or doubts about personal data protection, please contact the
          Company at&nbsp;
          <StyledLink href={SECURITY_MAIL}>security@cloudbest.com.</StyledLink>
        </Text>
        <Title>TRANSFER OF THE PERSONAL DATA</Title>
        <Text marginBottom="15px">
          The Company may be obliged to provide Personal Data to the following
          category of recipients, in particular:
        </Text>
        <ul>
          <li>
            business partners or Company’s affiliates – if necessary, in
            connection with The Company’s business activity, especially for the
            purpose of performing contracts with such third parties, providing
            services and ensuring the appropriate standards of performance and
            compliance with the provisions of the law and safety requirements,
            communicating with the User and with third parties, meeting
            financial obligations and responding to User’s requests and legal
            demands;
          </li>
          <li>
            third parties providing day-to-day services for the Company (e.g.
            legal advisors, tax advisors, IT service providers, auditors,
            accounting companies, outsourced workforce providers, customer
            service, software providers, e-mail operators, server hosting
            providers);
          </li>
          <li>
            entities entitled upon binding regulations (e.g. administrative
            bodies, supervisory bodies, regulators, law enforcement bodies);
          </li>
          <li>
            other parties as notified to the User at the time of Personal Data
            collection;
          </li>
        </ul>
        <Text>
          The Company may enter into written data processing contracts with
          another entity (further: the ‘Processor’). The right to enter into
          such contracts arises from the provisions of the law. The Company
          transfers the Personal Data solely in the scope necessary for
          realization of aim agreed with Processor and only to the extent
          necessary for the purposes of such aim, applies all required legal and
          technical measures for protection of the Personal Data in order to
          ensure the highest quality of the Services. <br />
          <br /> Additionally, please be informed that your Personal Data may be
          transferred outside the European Economic Area to entities such as
          Atlassian, Inc., Google LLC, Microsoft Corporation, Slack
          Technologies, Inc. The USA does not ensure an adequate level of
          protection of your data (mainly due to the loss of legal force of the
          Privacy Shield) due to the lack of a decision by the European
          Commission regarding the determination of an adequate level of
          personal data protection and we do not provide appropriate safeguards
          specified in Article 46 GDPR, including we have not concluded standard
          contractual clauses with the data recipient, and we do not have
          binding corporate rules. Therefore, we would like to inform you that
          there is a risk of insufficient protection of your data. In this case,
          the basis for the transfer of personal data is your voluntary consent
          in accordance with the Article 6(1)(a) GDPR.
        </Text>
        <Title>DATA RETENTION PERIOD</Title>
        <Text>
          The Company stores the Personal Data solely for the period necessary
          for obtaining purposes specified in this Privacy Policy for which the
          Personal Data have been collected. In certain extraordinary
          situations, longer retention periods may be required under the law
          (e.g. settlement, tax issues, legal proceedings/claims). However, in
          each case data retention is based on appropriate legal base – e.g.
          consent or binding provision of law.
        </Text>
        <Text marginBottom="15px">
          Personal Data will be processed over time specified as:&nbsp;
        </Text>
        <ul>
          <li>
            fulfilling the legal obligations arising from the provisions of the
            GDPR in connection with the exercise of User’s rights and archiving
            the requests made to us or informing about privacy risks, as well as
            fulfilling the obligations arising from tax law and other applicable
            legal regulations - until the expiry of the limitation
            periods;&nbsp;
          </li>
          <li>
            necessary in the context of contact through a form or other
            application and necessary for the preparation and presentation of a
            response or offer of cooperation - until the objection is raised or
            consent to such contact is revoked;&nbsp;
          </li>
          <li>
            to conduct marketing activities regarding the services and products
            we offer - until such processing is objected or until withdrawn of
            the consent to receive information with such content;&nbsp;
          </li>
          <li>
            if the processing of Personal Data is based on the consent – until
            it is withdrawn;&nbsp;
          </li>
        </ul>
        <Title>COOKIE FILES</Title>
        <Text marginBottom="15px">
          Please be informed that in order to function properly, the Site uses
          cookies. <br />
          <br />
          Cookies are small information in the form of a text file, sent by the
          website and then saved on the end device (computer, tablet,
          smartphone), which are used to browse the web.
          <br />
          <br /> This Site uses its own cookies (their purpose is to ensure the
          proper functioning of the site's functionality), while third party
          cookies (required in order to use the functions provided by third
          parties) are used by third party systems (Google Analytics, social
          media plugins etc.).
          <br />
          <br /> The The Site uses three types of Cookies:
        </Text>
        <ul>
          <li>
            Session Cookies – temporary files, saved on the User’s device until
            the user signs out, leaves the website or closes the browser;&nbsp;
          </li>
          <li>
            Permanent Cookies – saved on the User’s device for the time
            specified in its parameters or until its deletion by the User;&nbsp;
          </li>
          <li>
            Local storage - stored on the User’s device for a definite or
            indefinite period or until its deletion by the User;&nbsp;
          </li>
        </ul>
        <Text marginBottom="15px">
          The purpose of using Cookies is not to collect any Personal Data about
          the User visiting the Site, however it is not excluded that the
          information collected through Cookies will be recognized as Personal
          Data. <br />
          Cookies are used for the purposes of:
        </Text>
        <ul>
          <li>maintenance of the started session; </li>
          <li>
            configuration of the site and adjusting it to the preferences of the
            User using it;
          </li>
          <li>
            analysis of the clicks, displays and visits to the Site, maps of
            moving around the Site, time spent on the site;&nbsp;
          </li>
          <li>marketing analysis and statistics; </li>
        </ul>
        <Text marginBottom="15px">
          In some circumstances, the Controller may work with third parties to
          provide services on the website. For example, third-party analytics
          providers may use their own Cookies to collect information about
          User’s activities and/or the marketing statistics. This information
          may be used for statistical purposes and analysing of the traffic
          recorded on the site and also for optimizing the functionality of it
          (to be more accessible, intuitive). The Controller does not control
          these Cookies. In order to disable or reject third-party Cookies,
          please refer to the relevant third party’s website. <br />
          <br />
          External cookies of social networking sites (e.g. Facebook, LinkedIn,
          Twitter, Google) are attached by the Company to use their interactive
          features. <br />
          <br />
          The following third-parties can use their own Cookies on our website:
        </Text>
        <ul>
          <li>
            <StyledLink href={GOOGLE_URL}>www.google.com</StyledLink>
          </li>
          <li>
            <StyledLink href={LINKED_IN_URL}>www.linkedin.com</StyledLink>
          </li>
          <li>
            <StyledLink href={FACEBOOK_URL}>www.facebook.com</StyledLink>
          </li>
          <li>
            <StyledLink href={YOU_TUBE_URL}>www.youtube.com</StyledLink>
          </li>
          <li>
            <StyledLink href={INSTAGRAM_URL}>www.instagram.com</StyledLink>
          </li>
        </ul>
        <Text marginBottom="15px">
          During the first visit to the Site, information regarding Cookies used
          is displayed. By activating the "Accept" button, you agree to save
          cookies on the terminal device you use. You can change the settings
          for cookies or delete them from your browser.
          <br />
          <br />
          The links to guides how to disable the option of accepting Cookies in
          the popular browsers are presented below:
        </Text>
        <ul>
          <li>Chrome</li>
          <li>Firefox</li>
          <li>Safari</li>
          <li>Microsoft Edge</li>
        </ul>
        <Text>
          We point out that disabling, limiting or not accepting Cookies may
          result in malfunctioning of the Website or other unforeseeable
          difficulties.
        </Text>
        <Title>SERVER LOGS </Title>
        <Text>
          Collected server logs, containing, in particular User’s IP address,
          time of arrival of the request, the first line of the http request,
          the http response code, number of bytes sent by the server,
          information about the user’s browser, information about errors that
          occurred during the HTTP transaction, information about the type of
          devices is stored indefinitely.
          <br />
          <br /> Access to the above information is available to authorized
          persons who administer the Company’s Site. The above data does not
          identify specific Users browsing the website and is used only for
          server administration purposes. Files with logs can be analysed for
          statistical purposes (website traffic, errors).
        </Text>
        <Title>SECURITY MEASURES </Title>
        <Text marginBottom="15px">
          The Controller uses technical and organizational measures to protect
          the processed Personal Data adapted to the risks and categories of
          data covered by protection. The data is protected technically and
          organizationally against unauthorized access, processing in violation
          and change, loss, damage or destruction. <br />
          <br />
          We apply the principle of data minimization in accordance with the
          GDPR regulations, processing only those that are solely necessary to
          provide our services and maintain satisfactory quality for users.
          <br /> The Controller shall take the appropriate measures to ensure:
        </Text>
        <ul>
          <li>
            protection of personal data against loss, unauthorized access and/or
            use, destruction, modification or disclosure;
          </li>
          <li>
            appropriate technical and organizational safeguards (e.g. SSL
            certificate);
          </li>
          <li>
            protection of personal data according to the level of risk and
            specific categories of personal data;
          </li>
        </ul>
        <Text>
          We would like to emphasize that the use of the Internet and related
          services may cause the risk of cyber attacking, including unauthorized
          access by third parties to any data. Avoidance or minimization of
          possible threats is possible thanks to the application of appropriate
          technical security measures, e.g. through the use of antivirus
          programs and their current update, or other measures protecting the
          User's identification on the Internet. Detailed information on
          maintaining security on our Site can be found at&nbsp;
          <StyledLink href={SECURITY_MAIL}>security@cloudbest.com.</StyledLink>
        </Text>
        <Title>FINAL PROVISIONS </Title>
        <Text>
          This site may contain links to other external websites. The
          Administrator is not responsible for the content provided by these
          sites, and the User is obliged to read the privacy policy or
          regulations of these sites. <br />
          <br />
          The Controller is not liable for usage by other users or third parties
          of any personal data made publicly available by the users themselves
          through the&nbsp;
          <StyledLink href={CLOUDBEST_URL}>www.cloudbest.com.</StyledLink>
          <br />
          <br />
          Please, be informed that this Privacy Policy can be amended at any
          time. This Privacy Policy is updated on a regular basis and made
          available on the website{' '}
          <StyledLink href={CLOUDBEST_URL}>www.cloudbest.com.</StyledLink>
          <br />
          <br />
          We recommend that you read through the contents regularly. <br />
          <br />
          The current version of the Privacy Policy is effective from 27th
          November 2020.&nbsp;
        </Text>
      </Wrapper>
    </MainWrapper>
  );
};

export default PrivacyPolicy;
