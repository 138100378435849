import styled from 'styles/styled';

const Title = styled.h2`
  color: ${({ theme }) => theme.palette.light};
  margin-left: 0;
  position: relative;

  @media ${({ theme }) => theme.devices.medium} {
    margin-left: ${({ theme }) => theme.dimensions.spacingBase10 * 8}px;
  }

  @media ${({ theme }) => theme.devices.xlarge} {
    margin-left: 0;
  }
`;

export default Title;
