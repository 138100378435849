import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import PrivacyPolicy from 'components/PrivacyPolicy';

const PrivacyPolicyPage = ({
  data: {
    wpPage: { pageContent },
  },
}: any) => {
  return (
    <Layout>
      <SEO
        title="Privacy Policy - CloudBest"
        description="CloudBest manages international projects providing support within areas such as software development, content creation, customer support, marketing, business development for clients around the world."
      />
      <PrivacyPolicy data={pageContent} />
    </Layout>
  );
};

export default PrivacyPolicyPage;

export const query = graphql`
  query {
    wpPage(title: { in: "Privacy Policy" }) {
      pageContent {
        pageTitle
        description1part1
        description1part2
        title1
        description2
        title2
        description3part1
        description3part2
      }
    }
  }
`;
