import React from 'react';

import LineShape from 'components/ui/LineShape';
import Title from 'components/ui/Title';
import Paragraph from 'components/ui/Paragraph';

import { Wrapper, TextWrapper } from './styled';

interface Props {
  title?: string;
  subtitle?: string;
  description?: string;
}

const TopSection = ({ title, subtitle, description }: Props) => (
  <Wrapper>
    <LineShape />
    <TextWrapper>
      {title && (
        <Title>
          <strong>{title}</strong>
        </Title>
      )}
      {subtitle && <h3>{subtitle}</h3>}
      {description && <Paragraph>{description}</Paragraph>}
    </TextWrapper>
  </Wrapper>
);

export default TopSection;
